<script setup lang="ts">
import type { Api } from '@rialtic/api'

const props = defineProps<{
  claim: Api.ProcessedClaimWithInsights
}>()

const route = useRoute<'connectorId-claimId-claimLine'>()

useHead(() => ({
  title: `${route.params.claimId} / ${route.params.claimLine}`,
}))

const lineNumber = computed(() => route.params.claimLine)
const claimLine = computed(
  () =>
    props.claim.claimLines.find(
      (line) => `${line.lineNumber}` === `${lineNumber.value}`,
    ) as Api.ProcessedClaimWithInsights['claimLines'][number],
)
const recommendedInsight = computed(() =>
  getRecommendedInsight(claimLine.value?.insights),
)
const insightBadgeLabel = getInsightBadgeLabel(recommendedInsight.value)
const insightDecision = getInsightDecision(recommendedInsight.value)

const start = computed(() => claimLine.value?.servicedPeriod?.start)

const additionalIds = computed(
  () => props.claim?.additionalDetails?.additionalIds || null,
)
const billingName = computed(
  () => props.claim?.provider?.practitioner?.name?.family?.toLowerCase(),
)

const claimHeaderView = ref(false)

watch(
  recommendedInsight,
  (newVal, _oldVal) => {
    if (newVal) {
      $apiFetch('/api/analytics/log-insight-view', {
        method: 'POST',
        body: {
          connector_id: route.params.connectorId,
          policy_id: recommendedInsight.value?.policyId,
          policy_name: recommendedInsight.value?.policyName,
          concept_id: recommendedInsight.value?.insight.insight.conceptId,
          edit_type: recommendedInsight.value?.editType?.edit_type,
          claim_line_date_of_service_start:
            claimLine.value?.servicedPeriod.start,
          procedure_code: claimLine.value?.productOrService?.coding[0]?.code,
          carc: recommendedInsight.value?.insight.insight.reasonCodeLevel1,
          rarc: recommendedInsight.value?.insight.insight.reasonCodeLevel2,
          insight_id: recommendedInsight.value?.insight.insight.id,
        },
      })
    }
  },
  {
    immediate: true,
  },
)
</script>

<template>
  <main class="flex flex-col space-y-6 p-4 lg:pt-6">
    <div class="flex items-center space-x-2">
      <h1 class="h1 text-neutral-900">Claim Line {{ lineNumber }}</h1>
      <div
        v-if="insightBadgeLabel"
        class="subtitle-2 inline-flex items-center rounded-full bg-yellow-100 px-2 py-1 text-yellow-800"
      >
        {{ insightBadgeLabel }}
      </div>
    </div>
    <section v-if="insightDecision">
      <h2 class="h3 mb-2 text-neutral-900">Decision</h2>
      <UiContentBlock>
        <div class="mb-5">
          <h4 class="mb-3 text-xs text-neutral-700">Claim Line Status</h4>
          <p class="body-1 break-words text-neutral-900">
            {{ insightDecision.status }}
          </p>
        </div>
        <div
          v-if="recommendedInsight && recommendedInsight.editType"
          class="mb-5"
        >
          <h4 class="mb-3 text-xs text-neutral-700">Logic Type</h4>
          <p class="body-1 break-words text-neutral-900">
            {{ recommendedInsight.editType.edit_type }}
          </p>
        </div>
        <div class="mb-5">
          <h4 class="mb-3 mt-4 text-xs text-neutral-700">Reason</h4>
          <p class="body-1 break-words text-neutral-900">
            {{ insightDecision.reason }}
          </p>
        </div>
        <div v-if="recommendedInsight">
          <h4 class="mb-3 mt-4 text-xs text-neutral-700">Policy Name & ID</h4>
          <p class="body-1 break-words text-neutral-900">
            {{ recommendedInsight.policyName || '-' }}
          </p>
          <p class="body-1 break-words text-neutral-900">
            {{ recommendedInsight.policyId || '-' }}
          </p>
        </div>
      </UiContentBlock>
    </section>

    <SectionInfluencingClaims
      v-if="recommendedInsight"
      :claim="claim"
      :connector-id="route.params.connectorId"
      :insight-id="recommendedInsight?.insight.insight.id"
      :line-number="lineNumber"
    />

    <SectionReferenceDocuments
      v-if="recommendedInsight"
      :policy-id="recommendedInsight.insight?.insight?.policyId"
      :start-date="start"
    />

    <UiClaimLineDetails
      v-if="claimLine"
      :claim="claim"
      :claimLine="claimLine"
    />

    <div class="rounded-lg border border-neutral-200">
      <UiListItemExpand
        roundedBottom
        roundedTop
        data-analytics="claim-line-details"
      >
        <span class="rounded-lg text-sm">Claim Header</span>
        <template #content>
          <div class="py-4 px-5">
            <UiClaimHeader :claim="claim" :showHeader="false" />
          </div>
        </template>
      </UiListItemExpand>
    </div>
  </main>
</template>

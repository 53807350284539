<script setup lang="ts">
import showdown from 'showdown'
import { computed, ref } from '#imports'
import Button from './Button.vue'

const props = defineProps<{
  content?: string
  loading?: boolean
  title?: string
  transparent?: boolean
  custom?: boolean
  viewMoreFeature?: boolean
  noTopMargin?: boolean
}>()

const CHAR_MAX = 325

const contentEl = ref(null as HTMLElement | null)

const isHighEnough = ref(props.content && props.content.length > CHAR_MAX)

const showFullHeight = ref(false)
const toggleViewMore = () => {
  showFullHeight.value = !showFullHeight.value
}

const getHtml = (content: string) => {
  const converter = new showdown.Converter()
  const output = converter.makeHtml(content)
  return output
}

const data = computed(() => getHtml(props.content || ''))
</script>

<template>
  <div :class="{ 'bg-neutral-50': !transparent, 'rounded-lg p-5': !custom }">
    <div class="flex space-x-4">
      <slot name="header" />
      <p
        v-if="title"
        class="subtitle-2 mb-3 w-32"
        :class="[transparent ? 'text-primary-900' : 'text-neutral-900']"
      >
        {{ title }}
      </p>
      <div
        ref="contentEl"
        class="body-2"
        :class="{
          'body-2 text-neutral-900': !custom,
          'line-clamp-5': !showFullHeight && viewMoreFeature && isHighEnough,
          'prose-no-top-margin': noTopMargin,
          'prose-no-bottom-margin':
            viewMoreFeature && !showFullHeight && !isHighEnough,
        }"
        v-html="data"
      />
      <div v-if="loading" class="animate-pulse">
        <div class="mb-1 h-2 rounded bg-slate-200" />
        <div class="mb-1 h-2 rounded bg-slate-200" />
        <div class="mb-1 h-2 rounded bg-slate-200" />
        <div class="mb-1 h-2 rounded bg-slate-200" />
      </div>
    </div>
    <div
      v-if="viewMoreFeature && isHighEnough"
      class="mt-2"
    >
      <Button size="xs" variant="text" @click="toggleViewMore">
        {{ showFullHeight ? 'View less' : 'View more' }}
      </Button>
    </div>
    <slot name="footer" />
  </div>
</template>

<style>
.prose-no-top-margin > :first-child {
  margin-top: 0;
}
.prose-no-bottom-margin > :first-child {
  margin-bottom: 0;
}
</style>

<script setup lang="ts">
import type { Api } from '@rialtic/api'

const props = defineProps<{
  documentVersion: Api.ReferenceDocVersion
}>()

defineSlots<{
  default: (props: { source_document: Api.SourceDocument | null }) => any
}>()

const referenceDoc = computed(() => props.documentVersion.reference_document)
const sourceDoc = computed(() => props.documentVersion.source_document)
</script>

<template>
  <section class="rounded-lg bg-neutral-50 p-4 text-sm">
    <h3 class="font-medium">{{ referenceDoc.source }}</h3>
    <div
      v-if="referenceDoc.is_primary"
      class="subtitle-2 text-secondary-800 bg-secondary-50 py-.75 mt-1 inline-block whitespace-nowrap rounded-full px-3"
    >
      Primary source
    </div>
    <h4 class="mb-3 mt-4 text-xs text-neutral-700">Source</h4>
    {{ referenceDoc.source_type_level_1?.name }}

    <div v-if="documentVersion.policy_excerpt" class="mt-4">
      <h4 class="text-xs text-neutral-700">Policy Excerpt</h4>
      <UiRichTextBlock
        class="[&&]:p-0"
        :content="documentVersion.policy_excerpt"
        transparent
        view-more-feature
      />
    </div>
    <slot v-bind="{ source_document: sourceDoc }" />
  </section>
</template>
